/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import lcm from "../../../../images/Number Theory/lcm.webp";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong",
    pre: "pre",
    code: "code",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "least-common-multiple",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#least-common-multiple",
    "aria-label": "least common multiple permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Least Common Multiple"), "\n", React.createElement(_components.p, null, "and program in Rust to calculate it using Euclidean algorithm."), "\n", React.createElement(_components.h2, {
    id: "what-is-least-common-multiple--lcm-",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-least-common-multiple--lcm-",
    "aria-label": "what is least common multiple  lcm  permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is Least Common Multiple ( LCM )"), "\n", React.createElement(_components.p, null, "Least Common Multiple of two natural numbers is the smallest natural number that is divisible by both the numbers."), "\n", React.createElement(_components.p, null, "Lowest Common denominator is used for rational numbers, and it is LCM of denominators of both numbers."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "For Example :"), " LCM of 100 and 75 is 300"), "\n", "\n", React.createElement("div", {
    style: {
      textAlign: 'center'
    }
  }, React.createElement("img", {
    src: lcm,
    width: "100%",
    alt: "LCM of 150 and 210 is 1050"
  })), "\n", React.createElement(_components.h2, {
    id: "naive-approach",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#naive-approach",
    "aria-label": "naive approach permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Naive Approach"), "\n", React.createElement(_components.p, null, "Let us suppose we have to find Least Common Multiple ( LCM ) of 2 numbers, a and b."), "\n", React.createElement(_components.p, null, "The naive or brute force approach would be to traverse all the numbers from max(a, b) to a × b and find if it is divisible by both a and b. If yes, print the number and return."), "\n", React.createElement(_components.p, null, "We will not write its code, because it is very clumsy."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O( a × b )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O( 1 )")), "\n", React.createElement(_components.h2, {
    id: "efficient-euclidean-algorithm",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#efficient-euclidean-algorithm",
    "aria-label": "efficient euclidean algorithm permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Efficient Euclidean algorithm"), "\n", React.createElement(_components.p, null, "We know that, product of 2 numbers is equal to product of their GCD and LCM. Mathematically,"), "\n", React.createElement("center", null, " ", React.createElement("b", null, " a × b = GCD(a, b) × LCM(a, b)"), " "), "\n", React.createElement(_components.p, null, "We already saw ", React.createElement(_components.a, {
    href: "/number-theory/gcd-or-hcf/"
  }, "How To find HCF of 2 numbers using Euclidean Algorithm Here"), ". We will use this function to find LCM of 2 numbers."), "\n", React.createElement(_components.p, null, "So,"), "\n", React.createElement("center", null, " ", React.createElement("b", null, " LCM (a, b) = (a × b) / HCF(a, b)"), " "), "\n", React.createElement(_components.p, null, "Function using this approach is"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "// Find GCD\nfn gcd(mut a:usize, mut b:usize) -> usize{\n    if a==b { return a; }\n    if b > a {\n        let temp = a;\n        a = b;\n        b = temp;\n    }\n    while b>0 {\n        let temp = a;\n        a = b;\n        b = temp%b;\n    }\n    return a;\n}\n\nfn lcm(a:usize, b:usize) -> usize{\n    // LCM = a*b / gcd\n    return a*(b/gcd(a,b));\n}\n")), "\n", React.createElement(_components.p, null, "With driver code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use std::io::stdin;\n\n// Read Input\n\nfn take_vector() -> Vec<usize> {\n    let mut input = String::new();\n    stdin().read_line(&mut input).unwrap();\n    let arr: Vec<usize> = input.trim()\n        .split_whitespace().map(|x| x.parse().unwrap()).collect();\n    return arr;\n}\n\n// Magic Starts here\n\n// Find GCD\nfn gcd(mut a:usize, mut b:usize) -> usize{\n    if a==b { return a; }\n    if b > a {\n        let temp = a;\n        a = b;\n        b = temp;\n    }\n    while b>0 {\n        let temp = a;\n        a = b;\n        b = temp%b;\n    }\n    return a;\n}\n\nfn lcm(a:usize, b:usize) -> usize{\n    // LCM = a*b / gcd\n    return a * (b/gcd(a,b));\n}\n\n// Driver Code\n\npub fn main() {\n    let numbers = take_vector();\n    println!(\"{}\", lcm(numbers[0], numbers[1]));\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Input")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "210 150"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "1050"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O( log(min (a, b)) )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O( 1 )")), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "Least Common Multiple of two natural numbers is the smallest natural number that is divisible by both the numbers.\nIn this article, we made a program to compute Least Common Multiple (LCM) of two numbers in ", React.createElement(_components.strong, null, "Logarithmic Time Complexity"), " using Euclidean algorithm in Rust."), "\n", React.createElement(_components.p, null, "Here is optimized function for easy access."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn gcd(mut a:usize, mut b:usize) -> usize{\n    if a==b { return a; }\n    if b > a { let temp = a; a = b; b = temp; }\n    while b>0 { let temp = a; a = b; b = temp%b; }\n    return a;\n}\n\nfn lcm(a:usize, b:usize) -> usize{\n    return a*(b/gcd(a,b));\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Thank You")), "\n", "\n", React.createElement(SEO, {
    title: "LCM or Least Common Multiple using Euclidean algorithm - Rust Programming",
    description: "Least Common Multiple of two natural numbers is the smallest natural number that is divisible by both the numbers. We will make function to find LCM of two numbers and optimize it to logarithmic time complexity using Euclidean algorithm in Rust."
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
